exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-cart-jsx": () => import("./../../../src/pages/cart.jsx" /* webpackChunkName: "component---src-pages-cart-jsx" */),
  "component---src-pages-create-review-jsx": () => import("./../../../src/pages/create-review.jsx" /* webpackChunkName: "component---src-pages-create-review-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-links-jsx": () => import("./../../../src/pages/links.jsx" /* webpackChunkName: "component---src-pages-links-jsx" */),
  "component---src-pages-newsletter-jsx": () => import("./../../../src/pages/newsletter.jsx" /* webpackChunkName: "component---src-pages-newsletter-jsx" */),
  "component---src-pages-policies-privacy-policy-jsx": () => import("./../../../src/pages/policies/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-policies-privacy-policy-jsx" */),
  "component---src-pages-policies-product-safety-jsx": () => import("./../../../src/pages/policies/product-safety.jsx" /* webpackChunkName: "component---src-pages-policies-product-safety-jsx" */),
  "component---src-pages-policies-returns-policy-jsx": () => import("./../../../src/pages/policies/returns-policy.jsx" /* webpackChunkName: "component---src-pages-policies-returns-policy-jsx" */),
  "component---src-pages-policies-shipping-jsx": () => import("./../../../src/pages/policies/shipping.jsx" /* webpackChunkName: "component---src-pages-policies-shipping-jsx" */),
  "component---src-pages-policies-terms-of-service-jsx": () => import("./../../../src/pages/policies/terms-of-service.jsx" /* webpackChunkName: "component---src-pages-policies-terms-of-service-jsx" */),
  "component---src-pages-products-index-jsx": () => import("./../../../src/pages/products/index.jsx" /* webpackChunkName: "component---src-pages-products-index-jsx" */),
  "component---src-pages-products-stripe-product-metadata-type-index-jsx": () => import("./../../../src/pages/products/{stripeProduct.metadata__Type}/index.jsx" /* webpackChunkName: "component---src-pages-products-stripe-product-metadata-type-index-jsx" */),
  "component---src-pages-products-stripe-product-metadata-type-stripe-product-metadata-code-jsx": () => import("./../../../src/pages/products/{stripeProduct.metadata__Type}/{stripeProduct.metadata__Code}.jsx" /* webpackChunkName: "component---src-pages-products-stripe-product-metadata-type-stripe-product-metadata-code-jsx" */),
  "component---src-pages-products-stripe-product-metadata-type-stripe-product-name-jsx": () => import("./../../../src/pages/products/{stripeProduct.metadata__Type}/{stripeProduct.name}.jsx" /* webpackChunkName: "component---src-pages-products-stripe-product-metadata-type-stripe-product-name-jsx" */),
  "component---src-pages-search-jsx": () => import("./../../../src/pages/search.jsx" /* webpackChunkName: "component---src-pages-search-jsx" */),
  "component---src-pages-thankyou-jsx": () => import("./../../../src/pages/thankyou.jsx" /* webpackChunkName: "component---src-pages-thankyou-jsx" */)
}

